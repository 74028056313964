/** @format */

@use '@angular/material' as mat;

@import 'include-media';

// for button on mid- resolution view (768 - 1200px)
@include media('<=desktop') {
  marviq-dashboard mat-toolbar.mobile-toolbar.mat-toolbar-single-row {
    top: 24px;
    padding: 0 5px;
    background: transparent;
    width: 40px;

    height: 64px;

    button {
      background-color: transparent;
      left: 30px;
    }

    .mat-button-focus-overlay {
      background: transparent;
    }
  }
}

@include media('<=tablet') {
  .kms-theme {
    mat-sidenav.mat-sidenav.mat-drawer {
      top: 0px;

      .mat-list {
        height: 100vh;
      }
    }

    marviq-dashboard .main-container.mat-sidenav-container {
      top: 0px !important;
      height: 100vh;
      z-index: 4;

      &.mat-drawer-opened,
      &.sidenav-opened {
        z-index: 5;
      }
    }

    marviq-dashboard marviq-topnav {
      display: none;
    }

    marviq-dashboard mat-toolbar.mobile-toolbar.mat-toolbar-single-row {
      top: 0px;
      padding: 0 5px;
      background: transparent;
      height: initial;

      .mat-button-focus-overlay {
        background: transparent;
      }
    }

    mat-sidenav-content.mat-drawer-content {
      background: mat.get-color-from-palette($kms-palette-base, 100);
    }

    .main-content.change-on-mobile {
      margin-right: 0px;
      top: 40px;
      position: relative;
      z-index: 2;
      height: calc(100vh - 40px);
      box-shadow: none;
    }

    //  Fonts
    h1.mat-h1 {
      margin: 0 !important;
      font-size: 16px !important;
      line-height: 40px !important;
      text-align: center;
      background: white;
      padding: 0 30px 0 35px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: fixed;
      width: 100%;
      z-index: 3;
    }

    // Login/Auth page styles
    kms-auth-login-form,
    kms-auth-forgot-password-form,
    .request-password-confirmation-container,
    kms-auth-reset-password-form,
    kms-auth-set-password-form,
    app-choose-team-panel {
      .kms-auth {
        max-width: 100% !important;
        flex: 1 1 100% !important;
        min-width: 100% !important;
      }

      .mat-card {
        background-image: none;
        .kms-auth {
          padding: 40px 20px 60px 20px;

          .kms-logo {
            width: 200px !important;
          }

          .kms-auth-form {
            padding: 32px 25px;
          }
        }
      }
    }
  }
}
