/** @format */

@use '@angular/material' as mat;

.note-popover .note-popover-content,
.note-toolbar {
  padding: 0 0 5px 5px;
  margin: 0;
  background-color: mat.get-color-from-palette(
    map-get($ink-theme, primary),
    default
  );
}

.note-btn-group > .note-btn,
.note-btn-group > .note-btn-group {
  border-color: #e2e2e2;
}
