/** @format */

@use '@angular/material' as mat;

//
//  Inferred Material design settings;
//

$mat-form-field-inset-h: 0.75em;
$mat-button-icon-size: 40px;

//
//  Inkwala settings;
//
//  See also:
//
//    - https://xd.adobe.com/view/d52abc03-e089-4103-af19-a6d2d1e2aaab-8079/flow
//

//
//  Baseline
//

$breakpoints: (
  tablet: 768px,
  lamp: 1062px,
  improvement-plan: 1125px,
  desktop: 1200px,
);

$ink-radius-baseline: 15px;
$ink-radius-regular: 36px;

//
//  Sizes & Positioning
//

//  Branding
//
$ink-logo-horizon-offset-factor: calc(3600 / 3375);
$ink-logo-url: url('./assets/logo/logo.svg');

$ink-splash-logo-size: 340px;

//  Buttons
//
$ink-button-font-size: 18px;
$ink-button-radius: 22px;
$ink-button-height: 44px;
$ink-button-width-min: 8em;
$ink-button-padding-h: 20px;

$ink-button-small-font-size: 14px;

$ink-icon-button-size: 40px;

//  Headline / Title elements
//
$ink-headline-font-size: 32px;
$ink-headline-line-height-factor: 2;
$ink-headline-length-max: 594px;
$ink-headline-thickness: calc(
  $ink-headline-font-size * $ink-headline-line-height-factor
);
$ink-headline-comfort-zone: 24px;
$ink-headline-breadth: calc(
  $ink-headline-thickness + 2 * $ink-headline-comfort-zone
);
$ink-headline-radius: calc($ink-headline-thickness / 2);
$ink-headline-shadow: #1f1f1f90 0 9px 24px 1px;

$ink-title-font-size: 30px;
$ink-title-line-height-factor: 2;

//  App Navigation
//
$ink-nav-main-thickness: 253px;
$ink-nav-main-branding-space: calc(2 * $ink-headline-breadth);
$ink-nav-main-branding-size: calc($ink-nav-main-branding-space / 2);

$ink-nav-main-item-thickness: 56px;

//  Adjust according to content requirements;
$ink-nav-toolbar-length-min: 800px;
$ink-nav-toolbar-thickness: $ink-headline-thickness;
$ink-nav-toolbar-comfort-zone: $ink-headline-comfort-zone;
$ink-nav-toolbar-breadth: calc(
  $ink-nav-toolbar-thickness + 2 * $ink-nav-toolbar-comfort-zone
);
$ink-nav-toolbar-radius: calc($ink-nav-toolbar-thickness / 2);
$ink-nav-toolbar-shadow: #1f1f1f90 0 9px 24px 1px;

//  Real-estate compartmentalization between top-level headline and navigation toolbar;
//
$ink-headline-length: calc(
  100vw - $ink-nav-main-thickness - 2 * $ink-headline-comfort-zone -
    $ink-nav-toolbar-length-min - 2 * $ink-nav-toolbar-comfort-zone
);
$ink-nav-toolbar-length: calc(
  100vw - $ink-nav-main-thickness - 2 * $ink-nav-toolbar-comfort-zone -
    $ink-headline-length-max - 2 * $ink-headline-comfort-zone
);

//  Tabs
//
$ink-tab-heading-font-size: 26px;

$ink-tab-heading-thickness: $ink-headline-thickness;
$ink-tab-heading-radius: calc($ink-tab-heading-thickness / 2);
$ink-tab-heading-separation: 36px;

//  List-Filtering
//
$ink-filters-inset-h: 45px;
$ink-filters-inset-v-top: 33px;
$ink-filters-inset-v-bottom: 26px;

//  Expansion panels / Accordion
//
$ink-accordion-separation: 14px;

$ink-expansion-panel-radius: $ink-radius-regular;
$ink-expansion-panel-padding-h: 47px;
$ink-expansion-panel-padding-v: 32px;
$ink-expansion-panel-indicator-size: 9px;

$ink-expansion-panel-nested-bar-thickness: 7px;
$ink-expansion-panel-nested-bar-separation: 10px;

//  Form elements
//
$ink-form-field-radius: $ink-radius-baseline;
$ink-form-field-inset-h: $mat-form-field-inset-h;

//  Auth cards ( login, password-{forgot,{re,}set}, team-chooser )
//
$ink-auth-card-comfort-zone: 20px;

$ink-auth-card-decoration-logo-size: 180px;
$ink-auth-card-decoration-logo-horizon: calc(
  ($ink-auth-card-decoration-logo-size * $ink-logo-horizon-offset-factor) / 2
);
$ink-auth-card-decoration-logo-center: calc(
  ($ink-auth-card-decoration-logo-size) / 2
);

$ink-auth-card-radius: calc(2 * $ink-radius-baseline);
$ink-auth-card-padding-h: 52px;
$ink-auth-card-padding-v: 40px;

//  Dialogs ( add/edit-improvement )
//
$ink-dialog-radius: $ink-radius-regular;
$ink-dialog-padding-h: 35px;
$ink-dialog-padding-v: 30px;

$ink-dialog-action-positioned-top: 9px;
$ink-dialog-action-positioned-right: 14px;
$ink-dialog-action-positioned-bottom: auto;
$ink-dialog-action-positioned-left: auto;
$ink-dialog-action-positioned: $ink-dialog-action-positioned-top
  $ink-dialog-action-positioned-right $ink-dialog-action-positioned-bottom
  $ink-dialog-action-positioned-left;

//  Tables
//
$ink-table-gutter-size: 38px;

$ink-table-head-row-padding: 0 $ink-table-gutter-size;

$ink-table-row-comfort-zone: 19px 0;
$ink-table-row-radius: 26px;
$ink-table-row-padding: 40px $ink-table-gutter-size 24px;

$ink-table-col-separation: 20px;

$ink-table-col-cud-size: 2 * $ink-icon-button-size;
$ink-table-col-cud-size-extended: 3 * $ink-icon-button-size;
$ink-table-col-cud-size-extended-more: 5 * $ink-icon-button-size;

@mixin mat-cell-equal-width-limit(
  $columns-variable-count,
  $columns-fixed-total-width: 0
) {
  mat-cell,
  mat-header-cell,
  mat-footer-cell {
    max-width: calc(
      (
          100% - $columns-variable-count * $ink-table-col-separation -
            $columns-fixed-total-width
        ) / 8
    );
  }
}

//  Pages, Dashboard
//
$ink-page-inset-h: 45px;
$ink-page-inset-v-top: 33px;
$ink-page-dashboard-card-comfort-zone: 20px;

//
//  Colors
//

$ink-white: #ffffff;
//  - Background;
//  - Card Background;
//  - Form field backgound;
//  - Primary button foreground;

$ink-white-hue: #f6f5f9;
//  - Logo "white";

$ink-gray-lighter-er: #f7f7f7;
//  - Main content background;

$ink-gray-lighter: #f5f5f5;
//  - Auth card background;

$ink-gray-light: #e5e5e5;
//  - Selected Item Background;

$ink-gray-mid: #a7a7a7;
//  - Form field label/placeholder foreground;
//  - Password forgotten link foreground;

$ink-gray-dark: #707070;
//  - ???

$ink-anthracite: #1f1f1f;
//  - Text foreground;

$ink-green: #08c493;
//  - Logo checkkmarks

$ink-orange: #f98d2b;
//  - Logo bars;

$ink-purple: #4c3584;
//  - Primary Button background;

//
//  Palettes
//
//  https://v14.material.angular.io/guide/theming#palettes
//  https://m2.material.io/design/color/the-color-system.html#color-usage-and-palettes
//

$kms-palette-base: (
  50: rgba(9, 79, 159, 0.6),
  100: #f2f3f7,
  200: #fafafc,
  300: #bccbe3,
  400: #b8b3ff,
  500: #094f9f,
  600: #2f2889,
  700: #131b3e,

  A300: #99db77,
  A400: #d5ea8f,
  A500: #ffe096,
  A600: #ffc796,
  A700: #ff9696,
  contrast: (
    50: white,
    100: rgba(9, 79, 159, 0.6),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,

    A300: rgba(black, 0.87),
    A400: white,
    A500: white,
    A600: white,
    A700: white,
  ),
);

//
//  https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
//
$ink-palette-purple: (
  900: #3c2a72,
  //
  800: $ink-purple,
  // (== #4c3584 ) <=> #4b3584
  //
  700: #553b8d,
  600: #604397,
  500: #68489d,
  400: #7d62ab,
  300: #937dba,
  200: #b1a2ce,
  100: #d0c7e1,
  50: #ece8f3,

  //  From the complementary palette;
  //
  A700: #81983d,
  A400: #b3c468,
  A200: #d2dca5,
  A100: #e4eac8,

  contrast: (
    900: $ink-white,
    800: $ink-white,
    700: $ink-white,
    600: $ink-white,
    500: $ink-white,
    400: $ink-white,
    300: $ink-white,
    200: $ink-anthracite,
    100: $ink-anthracite,
    50: $ink-anthracite,

    A700: $ink-white,
    A400: $ink-anthracite,
    A200: $ink-anthracite,
    A100: $ink-anthracite,
  ),
);

$ink-palette-green: (
  900: #00582a,
  800: #007740,
  700: #00894d,
  600: #009a5a,
  500: #00a965,
  400: #00b77b,
  //
  300: $ink-green,
  //  (== #08c493 ) <=> #08c492
  //
  200: #75d4b1,
  100: #afe5cf,
  50: #def5ec,

  //  From the complementary palette;
  //
  A700: #d11542,
  A400: #ec4662,
  A200: #ea96a3,
  A100: #fbcbd7,

  contrast: (
    900: $ink-white,
    800: $ink-white,
    700: $ink-white,
    600: $ink-white,
    500: $ink-white,
    400: $ink-white,
    300: $ink-white,
    200: $ink-anthracite,
    100: $ink-anthracite,
    50: $ink-anthracite,

    A700: $ink-white,
    A400: $ink-anthracite,
    A200: $ink-anthracite,
    A100: $ink-anthracite,
  ),
);

$ink-palette-orange: (
  900: #e05923,
  800: #eb7126,
  700: #f27f28,
  //
  600: $ink-orange,
  //  (== #f98d2b ) <=> #f98f2b
  //
  500: #fe9a2d,
  400: #ffa83e,
  300: #ffb85c,
  200: #ffcc89,
  100: #ffe0b7,
  50: #fff3e2,

  //  From the complementary palette;
  //
  A700: #2775d7,
  A400: #46a5fb,
  A200: #91cafd,
  A100: #bcdefd,

  contrast: (
    900: $ink-white,
    800: $ink-white,
    700: $ink-white,
    600: $ink-white,
    500: $ink-anthracite,
    400: $ink-anthracite,
    300: $ink-anthracite,
    200: $ink-anthracite,
    100: $ink-anthracite,
    50: $ink-anthracite,

    A700: $ink-white,
    A400: $ink-anthracite,
    A200: $ink-anthracite,
    A100: $ink-anthracite,
  ),
);

//
//  Back- & Foregrounds
//

$ink-background: (
  // app-bar: map_get(mat.$grey-palette, 100),
  background: $ink-white,
  card: $ink-white,
  // dialog: white,
  // disabled-button-toggle: map_get(mat.$grey-palette, 200),
  // disabled-button: rgba(map_get($kms-palette-base, 500), 0.3),
  // disabled-list-option: map_get(mat.$grey-palette, 200),
  // focused-button: map_get($kms-palette-base, A300),
  // hover: rgba(black, 0.04),
  // raised-button: map_get($kms-palette-base, 500),
  // selected-button: map_get($kms-palette-base, A300),
  // selected-disabled-button: rgba(map_get($kms-palette-base, A300), 0.3),
  // status-bar: map_get(mat.$grey-palette, 300),
  // tooltip: #616161,
  // unselected-chip: map_get(mat.$grey-palette, 300),
  //
  //  Inkwala custom defs
  //
  ink-auth-card: $ink-gray-lighter,
  ink-main-content: $ink-gray-lighter-er,
  ink-selected-item: $ink-gray-light
);

$ink-foreground: (
  base: $ink-anthracite,
  // disabled-button: rgba(black, 0.26),
  // disabled-text: rgba(black, 0.38),
  // disabled: rgba(black, 0.38),
  // divider: rgba(black, 0.12),
  // dividers: rgba(black, 0.12),
  elevation: $ink-anthracite,
  // hint-text: rgba(black, 0.38),
  // icon: rgba(black, 0.54),
  // icons: rgba(black, 0.54),
  secondary-text: $ink-gray-mid,
  // slider-min: rgba(black, 0.87),
  // slider-off-active: rgba(black, 0.38),
  // slider-off: rgba(black, 0.26),
  text: $ink-anthracite,
  //
  //  Inkwala custom defs
  //
  ink-mat-header-cell: $ink-purple,
  ink-mat-expansion-panel-header: $ink-purple,
);

//
//  Typography
//

//
//  https://v14.material.angular.io/guide/typography
//
$ink-typography: mat.define-typography-config(
  //
  //  - Base font family
  //    ```scss
  //    $font-family:   'Roboto, "Helvetica Neue", sans-serif',
  //    ```
  $font-family: 'Ubuntu, sans-serif',
  //
  //  - 112px, one-off header, usually at the top of the page (e.g. a hero header).
  //
  //    ```scss
  //    $display-4:     define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  //    ```
  //
  //  - 56px, one-off header, usually at the top of the page (e.g. a hero header).
  //
  //    ```scss
  //    $display-3:     define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  //    ```
  //
  //  - 45px, one-off header, usually at the top of the page (e.g. a hero header).
  //
  //    ```scss
  //    $display-2:     define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  //    ```
  //
  //  - 34px, one-off header, usually at the top of the page (e.g. a hero header).
  //
  //    ```scss
  //    $display-1:     define-typography-level(34px, 40px, 400),
  //    ```
  //
  //  - Section heading corresponding to the <h1> tag.
  //
  //    ```scss
  //    $headline:      define-typography-level(24px, 32px, 400),
  //    ```
  $headline:
    mat.define-typography-level(
      $font-size: $ink-headline-font-size,
      $line-height: $ink-headline-line-height-factor,
      $font-weight: normal,
    ),
  //
  //  - Section heading corresponding to the <h2> tag.
  //
  //    ```scss
  //    $title:         define-typography-level(20px, 32px, 500),
  //    ```
  $title:
    mat.define-typography-level(
      $font-size: $ink-title-font-size,
      $line-height: $ink-title-line-height-factor,
      $font-weight: normal,
    ),
  //
  //  - Section heading corresponding to the <h3> tag.
  //
  //    ```scss
  //    $subheading-2:  define-typography-level(16px, 28px, 400),
  //    ```
  $subheading-2:
    mat.define-typography-level(
      $font-size: 27px,
      $line-height: 1.5,
      $font-weight: bold,
    ),
  //
  //  - Section heading corresponding to the <h4> tag.
  //
  //    `.mat-expansion-header`
  //
  //    ```scss
  //    $subheading-1:  define-typography-level(15px, 24px, 400),
  //    ```
  $subheading-1:
    mat.define-typography-level(
      $font-size: 20px,
      $line-height: 1.5,
      $font-weight: 500,
    ),
  //
  //  - Base body text.
  //
  //    ```scss
  //    $body-1:        define-typography-level(14px, 20px, 400),
  //    ```
  $body-1:
    mat.define-typography-level(
      $font-size: 18px,
      $line-height: 1.34375,
      $font-weight: normal,
    ),
  //
  //  - Bolder body text.
  //  - `mat-header-cell { font-weight }`;
  //
  //    ```scss
  //    $body-2:        define-typography-level(14px, 24px, 500),
  //    ```
  $body-2:
    mat.define-typography-level(
      $font-size: 18px,
      $line-height: 1.34375,
      $font-weight: 500,
    ),
  //
  //  - Smaller body and hint text;
  //  - `mat-header-cell { font-size }`;
  //
  //    ```scss
  //    $caption:       define-typography-level(12px, 20px, 400),
  //    ```
  $caption:
    mat.define-typography-level(
      $font-size: 18px,
      $line-height: 1.34375,
      $font-weight: 300,
    ),
  //
  //  - Buttons and anchors.
  //
  //    ```scss
  //    $button:        define-typography-level(14px, 14px, 500),
  //    ```
  $button:
    mat.define-typography-level(
      $font-size: $ink-button-font-size,

      // `$line-height` appears to be ignored for `<button>`s;
      // See workaround in `_kms-theme-stye.scss`;
      //
      $line-height: calc($ink-button-height / $ink-button-font-size),
      $font-weight: normal,
    ),
  //
  //  - Form input fields.
  //
  //    ```scss
  //    // Line-height must be unit-less fraction of the font-size.
  //    $input:         define-typography-level(inherit, 1.125, 400)
  //    ```
  $input:
    mat.define-typography-level(
      $font-size: inherit,
      $line-height: 1.125,
      $font-weight: 400,
    )
);

//
//  Create theme (use mat-dark-theme for themes with dark backgrounds)
//
//  https://v14.material.angular.io/guide/theming
//
$mat-theme: mat.define-light-theme(
  (
    color: (
      primary:
        mat.define-palette(
          $base-palette: $ink-palette-purple,
          $default: 800,
          $lighter: 300,
          $darker: 900,
          $text: 800,
        ),
      accent:
        mat.define-palette(
          $base-palette: $ink-palette-green,
          $default: 300,
          $lighter: 100,
          $darker: 700,
          $text: 600,
        ),
      warn:
        mat.define-palette(
          $base-palette: $ink-palette-orange,
          $default: 600,
          $lighter: 200,
          $darker: 800,
          $text: 900,
        ),
    ),
    typography: $ink-typography,
    density: 0,
  )
);

//  Retro-fit custom back- & foregrounds;
//
//  See also:
//
//    https://stackoverflow.com/a/77978797/5110545
//
$mat-theme-colors: map-get($mat-theme, color);

$ink-theme: map-merge(
  $mat-theme,
  (
    color:
      map-merge(
        $mat-theme-colors,
        (
          background:
            map-merge(map-get($mat-theme-colors, background), $ink-background),
          foreground:
            map-merge(map-get($mat-theme-colors, foreground), $ink-foreground),
        )
      ),
    background:
      map-merge(map-get($mat-theme-colors, background), $ink-background),
    foreground:
      map-merge(map-get($mat-theme-colors, foreground), $ink-foreground),
  )
);
