marviq-dashboard {
  height: 100vh;
  width: 100vw;
  display: block;
  position: relative;
}
.main-container {

  @media all and ( max-width: 768px ) {
    top:  140px !important;
  }

  position: absolute;
  top: 100px;
  bottom: 100px;
  height: calc(100vh - 200px);
  left: 0;
  right: 0;

}
iqc-footer {
  position: absolute;
  top: auto;
  bottom: 0px;
  width: 100vw;
  height: 100px;
  overflow: hidden;
  z-index: 1;

}
marviq-topnav {
  position: absolute;
  top: 0px;
  width: 100vw;
  height: 100px;
  overflow: hidden;
  z-index: 1;
}

.mat-toolbar.mobile-toolbar {
  position: absolute;
  top:      100px;
  height:   40px;
  z-index:  5;

  .mobile-logo-container {

    height:   40px;
    position: absolute;
    right:    0px;
    margin:   0 10px;

    img {

      height:   100%;
    }
  }

}
