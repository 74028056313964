.card-body-icon {
  position: absolute;
  z-index: 0;
  top: -25px;
  right: -25px;

  font-size: 5rem;
  @include rotate;
}

// Override breakpoints for card columns to work well with sidebar
.card-columns {
  @media (min-width: 576px) {
    column-count: 1;
  }
  @media (min-width: 768px) {
    column-count: 2;
  }
  @media (min-width: 1200px) {
    column-count: 2;
  }
}
