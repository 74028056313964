/** @format */

@use 'sass:math';
@use '@angular/material' as mat;

@use '@angular/cdk' as cdk;

//  To keep `ngx-material-timepicker-container` from undercutting instead of overlaying dialogs;
//
cdk.$overlay-container-z-index: 999;

@import 'include-media';
@import './_kms-theme.scss';

// Emit non-theme styles for core.
//
@include mat.core();

//  Emit theme styles for all components;
//
@include mat.all-component-themes($ink-theme);

//  Emit `.mat-typography { ... }`;
//
@include mat.typography-hierarchy($ink-theme);

// Mixins
@mixin transition($transition-property, $transition-time, $method) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}

//  App backdrop;
//
app-kms {
  display: block;
  min-width: 100vw;
  min-height: 100vh;

  background-image: url('./assets/backgrounds/app.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  //  "Loading Inkwala…" splash screen;
  //
  &:empty {
    background-image: $ink-logo-url, url('./assets/backgrounds/app.jpg');
    background-position:
      center
        calc(
          (100vh - $ink-splash-logo-size * $ink-logo-horizon-offset-factor) / 2
        ),
      center;
    background-repeat: no-repeat, no-repeat;
    background-size: $ink-splash-logo-size, cover;

    &::before {
      content: 'Loading Inkwala…';
      position: absolute;
      top: 1ex;
      left: 1em;
      color: mat.get-color-from-palette(map-get($ink-theme, primary), text);
    }
  }

  //  "Powered by..." banner
  //
  &::after {
    content: 'Inkwala is powered by Het Gezonde Net, Fys’Optima en InPraktijk.';
    position: absolute;
    right: 2em;
    bottom: 1ex;
    font-size: 14px;
    color: mat.get-color-from-palette(map-get($ink-theme, primary), text);
  }
}

//  Fix vertical centering of icon in `icon-button`s;
//
.mat-icon-button > .mat-button-wrapper {
  display: flex;
  justify-content: center;
}

.kms-theme {
  //
  //  Inkwala Material Design overrides;
  //

  //  Buttons
  //
  .mat-flat-button {
    &.attached {
      &.north-east {
        top: 0;
        left: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      position: absolute;
      padding: 0 21px;
      line-height: 2.5;

      + * {
        margin-top: 1.6em;
      }
    }

    &.action-advance {
      &::after {
        content: 'arrow_forward_ios';
        font-family: 'Material Symbols Outlined';
        display: block;
        position: absolute;
        top: 0;
        right: 15px;
      }

      padding-right: calc(21px + 1em);
    }

    &.action-retreat {
      &::after {
        content: 'arrow_back_ios_new';
        font-family: 'Material Symbols Outlined';
        display: block;
        position: absolute;
        top: 0;
        left: 15px;
      }

      padding-left: calc(21px + 1em);
    }

    .mat-button-wrapper > .mat-icon {
      margin-top: -0.2ex;
    }

    border-radius: $ink-button-radius;
    padding: 0 $ink-button-padding-h;
    min-width: min($ink-button-width-min, 100%);

    //  Workaround MD ignoring `$line-height` from its own `$typograhpy.button` def;
    //
    @include mat.typography-level($ink-typography, button);

    &.small {
      font-size: $ink-button-small-font-size;
    }
  }

  //  Chips
  //
  //  Mimic `button` appearance
  //
  mat-chip {
    border-radius: $ink-button-radius;
    padding: calc(($ink-button-height - $ink-button-font-size - 2px) / 2)
      $ink-button-padding-h;
    height: auto;
    min-width: min($ink-button-width-min, 100%);

    text-align: center;
    line-height: calc($ink-button-font-size + 2px);
  }

  //  Accordion
  //
  .mat-accordion {
    display: flex;
    flex-flow: column nowrap;
    gap: $ink-accordion-separation;
  }

  //  Expansion panels
  //
  .mat-expansion-panel {
    background-color: transparent;

    &.mat-expanded {
      overflow: visible;
    }

    > .mat-expansion-panel-header {
      transition-property: background-color, margin;

      &.mat-expanded {
        margin-bottom: -11px;

        background-color: transparent;
        color: mat.get-color-from-palette(map-get($ink-theme, accent), text);
      }

      border-radius: $ink-expansion-panel-radius;
      height: 83px;

      background-color: mat.get-color-from-palette(
        map-get($ink-theme, background),
        background
      );
      color: mat.get-color-from-palette(
        map-get($ink-theme, foreground),
        ink-mat-expansion-panel-header
      );

      .mat-expansion-panel-header-title {
        justify-content: space-between;
        gap: 1em;

        color: currentColor;

        //  Not a real Material-Design class, we made it up;
        //
        .mat-expansion-panel-header-label {
          color: mat.get-color-from-palette(map-get($ink-theme, accent), text);
          &:not(:empty) {
            margin-left: auto;
          }
        }

        .mat-content {
          //  Class is set when an outer `<mat-accordion hideToggle>` exists
          //
          &.mat-content-hide-toggle {
            margin-right: calc(
              $ink-expansion-panel-indicator-size * math.sqrt(2)
            );
          }
        }
      }

      .mat-expansion-indicator::after {
        color: currentColor;

        //  Sets size of indicator arms:
        //
        padding: calc(($ink-expansion-panel-indicator-size - 2px) / 2);
        margin: calc(
          $ink-expansion-panel-indicator-size * (math.sqrt(2) - 1) / 2
        );
      }
    }

    //  Effectively set "padding" of `.mat-expansion-panel-content`, while allowing it to be `height: 0` for real when collapsed, which normal `padding`
    //  doesn't do;
    //
    .mat-expansion-panel-content {
      > * {
        border: 0;
        padding: 0;
      }
      > :first-child:not(:empty) {
        margin-top: 0;
      }
      > :last-child:not(:empty) {
        margin-bottom: $ink-expansion-panel-padding-v;
      }
    }
  }

  //  Form controls
  //
  .mat-form-field-appearance-fill {
    .mat-form-field-flex {
      border-radius: $ink-form-field-radius;
    }
    .mat-form-field-underline {
      visibility: hidden;
    }
  }

  [type='password'].mat-input-element {
    font-family: monospace;
    font-size: 19px;
    letter-spacing: 0.67ex;
    color: mat.get-color-from-palette(map-get($ink-theme, primary), default);
  }

  [type='time'] {
    //  Counteract MD making `<input type=time>`-s higher than they need to be;
    //
    &.mat-input-element::after {
      content: none;
    }

    //  Hide native picker trigger in Chrome;
    //
    &::-webkit-calendar-picker-indicator {
      visibility: hidden;
    }
  }

  .mat-checkbox[appearance='fill'] {
    padding-bottom: calc(1.34375em - 0.2em);

    .mat-checkbox-layout {
      border-radius: $ink-form-field-radius;
      margin-bottom: 0.2em;
      padding: 0.61111em $ink-form-field-inset-h;
      background-color: mat.get-color-from-palette(
        map-get($ink-theme, foreground),
        base,
        0.04
      );
    }
  }

  textarea::-webkit-resizer {
    border-bottom-right-radius: 80%;
    border: solid 2px transparent;
    box-shadow:
      1px 1px 2px -1px mat.get-color-from-palette(map-get($ink-theme, accent), 200),
      inset -1px -1px 2px -1px mat.get-color-from-palette(map-get($ink-theme, accent), 200);
  }

  //  Tables
  //
  mat-table {
    background: transparent;

    //  Align chips' texts with baseline
    //
    mat-chip-list {
      display: block;
      margin-top: -14px;
    }

    mat-chip {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      overflow-wrap: normal;

      &.is-status {
        background-color: mat.get-color-from-palette(
          map-get($ink-theme, 'foreground'),
          'base'
        );
        color: mat.get-color-from-palette(
          map-get($ink-theme, 'background'),
          'background'
        );
      }
    }

    .mat-icon-button {
      margin-top: -9px;
    }
  }

  mat-row,
  mat-header-row,
  mat-footer-row {
    margin: $ink-table-row-comfort-zone;
    border: none;

    column-gap: $ink-table-col-separation;
  }

  mat-header-row,
  mat-footer-row {
    padding: $ink-table-head-row-padding;
    min-height: initial;
  }

  mat-row {
    align-items: normal;

    border-radius: $ink-table-row-radius;
    padding: $ink-table-row-padding;

    background-color: mat.get-color-from-palette(
      map-get($ink-theme, background),
      background
    );

    &.cancelled {
      text-decoration: line-through
        rgba(mat.get-color-from-palette(map-get($ink-theme, accent), A700), 0.6)
        solid 0.1666667em;
    }
  }

  mat-cell,
  mat-header-cell,
  mat-footer-cell {
    display: block;

    //  MD gives first- and last cells of a row respectively a hardcoded left- and right padding of 24px;
    //  See also:
    //
    //    - https://github.com/angular/components/blob/14.2.7/src/material/table/_table-flex-styles.scss#L4
    //
    //  Counteract this;
    //
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  mat-cell {
    overflow: visible;
  }

  mat-header-cell {
    color: mat.get-color-from-palette(
      map-get($ink-theme, foreground),
      ink-mat-header-cell
    );
  }

  //  Tabs
  //
  .mat-tab-header,
  .mat-tab-nav-bar {
    border-radius: $ink-tab-heading-radius;
    border: 0;
    margin-bottom: $ink-tab-heading-separation;

    .mat-ink-bar {
      z-index: -1;
      border-radius: $ink-tab-heading-radius;
      height: 100%;
    }
  }

  .mat-tab-label-container,
  .mat-tab-link-container {
    background-color: mat.get-color-from-palette(
      map-get($ink-theme, background),
      background
    );
  }

  .mat-tab-label,
  .mat-tab-link {
    opacity: 1;
    transition-duration: 500ms;
    border-radius: $ink-tab-heading-radius;
    height: $ink-tab-heading-thickness;

    font-size: $ink-tab-heading-font-size;

    &:not(:first-child) {
      margin-left: -$ink-tab-heading-radius;
    }

    &:not(:last-child) {
      margin-right: -$ink-tab-heading-radius;
    }

    &.mat-tab-label-active {
      color: mat.get-color-from-palette(
        map-get($ink-theme, background),
        background
      );
    }
  }

  //  Adjust tabs' container margins outward so that the enter- and leave
  //  animations of a single tab's content can happen from- and to the
  //  page real-estate's outer edges;
  //
  .mat-tab-nav-panel {
    display: block;
  }
  .mat-tab-nav-panel,
  .mat-tab-body-wrapper {
    margin: 0 -#{$ink-page-inset-h};
    width: calc(100% + 2 * $ink-page-inset-h);
  }

  //  Move page real estate's padding inward to tabs' content;
  //
  .mat-tab-nav-panel > router-outlet ~ * {
    display: block;
  }
  .mat-tab-body-content,
  .mat-tab-nav-panel > router-outlet ~ * {
    padding: 0 $ink-page-inset-h;
  }

  //  Everything
  //
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    scrollbar-color: mat.get-color-from-palette(
        map-get($ink-theme, foreground),
        base
      )
      mat.get-color-from-palette(map-get($ink-theme, background), background);
  }

  .mat-simple-snackbar span {
    text-align: center;
    width: 100%;
    color: mat.get-color-from-palette($kms-palette-base, A700);
  }

  // TODO sample success snack bar
  .mat-simple-snackbar-success span {
    color: mat.get-color-from-palette($kms-palette-base, A300);
  }

  //  Angular material components;
  //
  .mat-toolbar.mat-primary,
  .mat-toolbar {
    background: transparent;
  }

  .mat-list .mat-list-item,
  .mat-list-item {
    cursor: pointer;

    &:hover,
    &:active,
    &.active-link {
      background-color: mat.get-color-from-palette(
        map-get($ink-theme, background),
        ink-selected-item
      );
      color: mat.get-color-from-palette(map-get($ink-theme, primary), text);
      font-weight: bold;
    }

    .mat-line {
      color: currentColor;
      font-weight: inherit;
    }
  }

  .mat-button-focus-overlay {
    background-color: transparent;
  }

  form {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
  }

  .mat-tooltip.smiley {
    width: 300px;
  }

  .no-rights-info {
    @include mat.typography-level($ink-typography, title);

    color: mat.get-color-from-palette($kms-palette-base, A700);
    text-align: center;
    padding: 30px;
    font-weight: normal;
    max-width: 500px;
    margin: 0 auto;
  }

  //  Marviq `@iq/ng-core` components
  //
  marviq-dashboard {
    [topNav] {
      position: absolute;
      z-index: 5;

      right: 0;
      height: $ink-nav-toolbar-thickness;
      min-width: $ink-nav-toolbar-length-min;
      width: $ink-nav-toolbar-length;

      margin: $ink-nav-toolbar-comfort-zone;

      @include mat.typography-level($ink-typography, body-1);
    }

    mat-sidenav-container {
      top: 0;
      height: 100vh;

      background: transparent;

      .mat-sidenav {
        width: $ink-nav-main-thickness;
        padding-top: $ink-nav-main-branding-space;

        background-image: $ink-logo-url;
        background-position: center
          calc(
            (
                $ink-nav-main-branding-space - $ink-nav-main-branding-size *
                  $ink-logo-horizon-offset-factor
              ) / 2
          );
        background-repeat: no-repeat;
        background-size: $ink-nav-main-branding-size;

        border: none;
        box-shadow: mat.get-color-from-palette(
            map-get($ink-theme, foreground),
            elevation
          )
          0px 0px 20px 0px;

        //  This is settings acordion that should be variable in the core library
        //  For now we have to hide it
        mat-accordion {
          display: none;
        }

        .mat-list {
          padding-top: 0px;
        }

        .mat-list .mat-list-item {
          height: $ink-nav-main-item-thickness;

          .mat-list-text {
            padding-left: 15px;
          }
        }
      }
    }

    auth-login-form .mat-card {
      text-align: center;
    }

    .mat-toolbar-row {
      height: $ink-nav-toolbar-thickness;
      padding: 0px;

      @include media('<=lamp') {
        justify-content: end;
      }
    }

    mat-toolbar.mobile-toolbar {
      top: 80px;
      position: fixed;
      z-index: 5;
      height: 40px;
    }

    iqc-user-panel {
      @include mat.typography-level($ink-typography, subheading-1);

      height: 100%;
      text-align: center;

      .user-panel {
        height: 100%;
        padding-top: 25px;
      }

      .user {
        padding-right: 20px;
      }

      .mat-icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 30px;
        position: relative;
        top: -5px;
        cursor: pointer;
      }
    }
  }

  iqc-menu-hor-item {
    .mat-list-item {
      float: none;
    }

    .mat-list-text {
      padding: 5px;
      width: 200px;
    }
  }

  //  Custom components

  .main-content {
    overflow-y: auto;

    height: calc(100vh - $ink-nav-toolbar-breadth);
    padding: $ink-page-inset-v-top $ink-page-inset-h 0;

    background-color: mat.get-color-from-palette(
      map-get($ink-theme, background),
      ink-main-content
    );
  }

  .mat-form-field {
    &.constraint-violation {
      color: mat.get-color-from-palette(map-get($ink-theme, warn), text);
    }

    mat-datepicker ~ mat-icon {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
    }

    ngx-material-timepicker-toggle {
      position: absolute;
      right: 0;
      top: 0;

      > button {
        padding: 0;
      }
    }
  }

  ngx-material-timepicker-content .timepicker {
    overflow: hidden;
    border-radius: $ink-radius-regular;

    .timepicker__header {
      background-color: mat.get-color-from-palette(
        map-get($ink_theme, primary)
      );
    }

    .clock-face__clock-hand,
    .clock-face__number > span.active {
      background-color: mat.get-color-from-palette(
        map-get($ink_theme, primary)
      );
    }

    .clock-face__clock-hand_minute:before {
      border: 4px solid mat.get-color-from-palette(map-get($ink_theme, primary));
    }

    .timepicker__actions {
      gap: 8px;
    }
  }

  .dialog-tooltip {
    max-width: 450px;
  }

  .kms-list {
    .cud-icons {
      flex: 0 0 $ink-table-col-cud-size;
      color: mat.get-color-from-palette($kms-palette-base, 600);
      text-align: right;

      &.extended {
        flex: 0 0 $ink-table-col-cud-size-extended;
      }
      &.extended-more {
        flex: 0 0 $ink-table-col-cud-size-extended-more;
      }
    }
  }

  //  Filtered lists' filters
  //
  .filters-container {
    margin-bottom: 30px;

    .mat-expansion-panel {
      &.mat-expanded {
        background: $ink-gray-light;

        margin: 0 0 0 -#{$ink-filters-inset-h};

        > .mat-expansion-panel-header {
          margin: 0;
        }
      }

      margin: -#{$ink-filters-inset-v-top} 0 0 -#{$ink-filters-inset-h};
      padding: $ink-filters-inset-v-top $ink-filters-inset-h 0;
      border-radius: 0 $ink-radius-regular $ink-radius-regular 0;

      .mat-expansion-panel-header {
        &,
        &:hover {
          background-color: mat.get-color-from-palette(
            map-get($ink-theme, primary),
            default
          );
        }
        color: mat.get-color-from-palette(
          map-get($ink-theme, primary),
          default-contrast
        );

        display: inline-flex;
        height: $ink-button-height;
        min-width: min($ink-button-width-min, 100%);
        border-radius: $ink-button-radius;

        font-weight: normal;

        .mat-expansion-panel-header-title {
          justify-content: center;
        }
      }

      .mat-checkbox[appearance='fill'] .mat-checkbox-layout,
      .mat-form-field-appearance-fill .mat-form-field-flex {
        background-color: mat.get-color-from-palette(
          map-get($ink-theme, background),
          background
        );
      }
    }

    .mat-expansion-panel-content {
      > :first-child:not(:empty) {
        margin-top: $ink-filters-inset-v-bottom;
      }
      > :last-child:not(:empty) {
        margin-bottom: $ink-filters-inset-v-bottom;
      }
    }
  }

  .group-content .mat-card-header-text {
    margin: 0;
    width: 100%;
  }

  .cdk-overlay-backdrop {
    backdrop-filter: blur(33px);
    background: rgba(128, 128, 128, 0.1);
  }

  .mat-dialog-container {
    position: relative;

    border-radius: $ink-dialog-radius;
    padding: $ink-dialog-padding-v $ink-dialog-padding-h;

    overflow: hidden;

    > * {
      display: flex;
      height: 100%;
      flex-flow: column nowrap;
      gap: $ink-dialog-padding-v;
    }

    .mat-dialog-title,
    .mat-dialog-actions {
      flex-shrink: 0;
    }

    .mat-dialog-content {
      //  Ensure scrollbar is offset to the far right, despite container padding;
      //
      margin: 0 (-$ink-dialog-padding-h);
      padding: 0 $ink-dialog-padding-h;

      flex: auto;
      overflow-y: auto;
    }

    .mat-dialog-actions {
      margin: 0;
      padding: 0;

      justify-content: flex-end;

      button.dialog-action-close {
        position: absolute;
        inset: $ink-dialog-action-positioned;
        margin: 0;
      }
    }

    .mat-accordion {
      .mat-expansion-panel {
        .mat-checkbox[appearance='fill'] .mat-checkbox-layout,
        .mat-form-field-appearance-fill .mat-form-field-flex {
          background-color: mat.get-color-from-palette(
            map-get($ink-theme, background),
            background
          );
        }

        .mat-subheading-1 {
          :not(:first-child) {
            margin-top: 30px;
          }
          color: mat.get-color-from-palette(map-get($ink-theme, accent), text);
        }

        .table-placeholder {
          border-radius: $ink-form-field-radius;
          margin: $ink-accordion-separation 0;
          padding: 16px $ink-form-field-inset-h 6px;
          min-height: 56px;
          background-color: mat.get-color-from-palette(
            map-get($ink-theme, background),
            background
          );
        }

        .mat-row,
        .mat-header-row,
        .mat-footer-row {
          margin: $ink-accordion-separation 0;
        }

        .mat-header-row,
        .mat-footer-row {
          padding: 0 $ink-form-field-inset-h;
        }

        .mat-row {
          border-radius: $ink-form-field-radius;
          padding: 16px $ink-form-field-inset-h 6px;
          min-height: 34px;
        }

        > .mat-expansion-panel-header {
          &:not(.mat-expanded) {
            background: $ink-gray-lighter-er;
          }

          color: mat.get-color-from-palette(
            map-get($ink-theme, foreground),
            ink-mat-expansion-panel-header
          );
        }

        .mat-expansion-panel-content {
          background: $ink-gray-lighter-er;
          border-radius: $ink-expansion-panel-radius;

          //  Effectively set "padding" of `.mat-expansion-panel-content`, while allowing it to be `height: 0` for real when collapsed, which normal `padding`
          //  doesn't do;
          //
          > * {
            margin: 0 $ink-expansion-panel-padding-h;
          }
          > :first-child {
            margin-top: $ink-expansion-panel-padding-v;
          }
          > :last-child {
            margin-bottom: $ink-expansion-panel-padding-v;
          }
        }
      }
    }

    // Style for the children expansion panels
    .children-expansion-container {
      margin-left: calc(
        -1 * ($ink-expansion-panel-nested-bar-thickness +
              $ink-expansion-panel-nested-bar-separation)
      );

      .mat-accordion {
        gap: 0;

        .mat-expansion-panel {
          &.mat-expanded {
            background: $ink-gray-lighter-er;
          }

          .mat-expansion-panel-header {
            background: transparent;
            margin: 0;
            padding: 8px 24px;
            min-height: 56px;
            height: auto;
            border-radius: 0;
          }

          .mat-expansion-panel-content {
            background: transparent;
            border-radius: 0;

            & > * {
              margin: 10px 0 0 $ink-expansion-panel-nested-bar-separation;

              &:empty {
                margin: 0;
              }
            }
          }
        }
      }

      .insertable-item {
        margin-bottom: $ink-accordion-separation;
        border-width: 0 0 0 $ink-expansion-panel-nested-bar-thickness;
        border-style: solid;
        border-radius: $ink-radius-baseline;
        border-color: mat.get-color-from-palette(map-get($ink-theme, primary));

        background-color: mat.get-color-from-palette(
          map-get($ink-theme, background),
          background
        );

        &.draggable-items:not(.cdk-drag-disabled) mat-expansion-panel-header {
          cursor: move;
        }

        &.has-error {
          border-color: mat.get-color-from-palette(map-get($ink-theme, warn));

          mat-expansion-panel-header .mat-expansion-panel-header-title,
          mat-expansion-panel-header-description {
            color: mat.get-color-from-palette(map-get($ink-theme, warn), text);
          }
        }

        &.is-new:not(.has-error) {
          border-color: mat.get-color-from-palette(map-get($ink-theme, accent));
        }

        &.removed {
          border-color: rgba(
            mat.get-color-from-palette(map-get($ink-theme, accent), A700),
            0.6
          );

          text-decoration: line-through
            rgba(
              mat.get-color-from-palette(map-get($ink-theme, accent), A700),
              0.6
            )
            solid 3px;
        }
      }
    }
  }

  .delete-dialog {
    .mat-dialog-container {
      .mat-dialog-title {
        color: mat.get-color-from-palette(map-get($ink-theme, warn), text);
        text-align: center;
      }

      .mat-dialog-content {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: 20px;

        .dialog-object-name {
          font-weight: bold;
        }
      }
    }
  }

  .errors-list-tooltip {
    white-space: pre-line;
    text-align: center;
    color: mat.get-color-from-palette($kms-palette-base, A700);
    background: rgba(mat.get-color-from-palette($kms-palette-base, 700), 0.5);
    margin: 0;

    width: 500px;
    max-width: 80vw;
    border-radius: 0;

    @include mat.typography-level($ink-typography, body-2);
  }

  //  Headings
  //
  .mat-h1 {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    max-width: $ink-headline-length-max;
    width: $ink-headline-length;
    margin: $ink-headline-comfort-zone;
    padding: 0 $ink-headline-radius;
    border-radius: $ink-headline-radius;
    box-shadow: $ink-headline-shadow;

    // we are now hiding sidebar on 1200px
    @include media('<=desktop') {
      width: calc($ink-headline-length + 253px); // 253 = width of the sidebar
      padding-left: 50px;
    }

    @include media('<=lamp') {
      width: calc(
        $ink-headline-length + 253px + 200px
      ); // 253 = width of the sidebar, 200px removed gaps
    }

    @include media('<=920px') {
      width: calc(
        $ink-headline-length + 253px + 400px
      ); // 253 = width of the sidebar, 400px of picked free space for expanding the possible section title
    }

    @include media('<=tablet') {
      padding-left: initial;
    }

    background-color: mat.get-color-from-palette(
      map-get($ink-theme, background),
      background
    );

    text-align: center;
    text-overflow: ellipsis;
    text-wrap: nowrap;

    span.mat-h1-light {
      font-weight: 300;
    }
  }

  .mat-subheading-1 {
    color: mat.get-color-from-palette(map-get($ink-theme, primary), text);
  }

  mat-sidenav-content {
    > div {
      display: none;
    }

    marviq-container {
      > p {
        margin: 0;

        > router-outlet + * {
          //  prevent margin-collapse on `mat-sidenav-content` ancestor;
          display: flow-root;
        }
      }
    }
  }

  //  Structural
  //
  .flex-rows {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    align-items: baseline;
    justify-content: flex-end;

    > :not(.no-grow) {
      flex: auto;
    }
  }

  //  While dragged
  //
  .cdk-drag-preview {
    &.insertable-item {
      border-color: mat.get-color-from-palette(map-get($ink-theme, primary));
      border-width: 0 0 0 $ink-expansion-panel-nested-bar-thickness;
      border-style: solid;
      border-radius: $ink-radius-baseline;
      background-color: mat.get-color-from-palette(
        map-get($ink-theme, background),
        background
      );
    }
  }
}

:focus {
  outline: none;
}

.mal-inline-table {
  margin: 20px;

  h4 {
    text-align: center;
    padding-top: 10px;
  }

  .cud-icons {
    text-align: right;
    color: rgba(0, 0, 0, 0.54);

    &.add-new mat-icon {
      font-size: 35px;
      height: 35px;
      width: 35px;
    }

    mat-icon.mat-icon {
      cursor: pointer;

      &:hover:not(.disabled) {
        color: rgba(0, 0, 0, 0.87);
      }

      &.disabled {
        cursor: auto;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  mat-row.mat-row {
    &.edit-mode:not(.has-error) {
      background-color: lightyellow;
    }

    &.has-error {
      background-color: lightcoral;
    }
  }
}

.kms-theme {
  //
  //  Auth cards ( login, password-{forgot,{re,}set}, team-chooser )
  //
  kms-auth-login-form,
  kms-auth-forgot-password-form,
  kms-auth-reset-password-form,
  kms-auth-set-password-form,
  app-choose-team-panel,
  .request-password-confirmation-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    min-width: 100vw;
    min-height: 100vh;

    background-color: mat.get-color-from-palette(
      map-get($ink-theme, background),
      ink-auth-card
    );

    background-image: url('./assets/backgrounds/app.jpg');
    background-position: center;
    background-size: cover;

    .mat-card {
      border-radius: $ink-auth-card-radius;
      margin: calc(
          $ink-auth-card-comfort-zone + $ink-auth-card-decoration-logo-horizon
        )
        $ink-auth-card-comfort-zone calc($ink-auth-card-comfort-zone * 2);
      padding: 0 $ink-auth-card-radius;
      width: 100%;
      min-height: 451px;
      max-width: 956px;

      background-attachment: scroll;
      background-color: mat.get-color-from-palette(
        map-get($ink-theme, background),
        ink-auth-card
      );
      background-image: url('./assets/backgrounds/login-splash.jpg');
      background-position: 112.5% 11%;
      background-repeat: no-repeat;
      background-size: 57% 100%;

      .kms-auth {
        padding: $ink-auth-card-padding-v $ink-auth-card-padding-h
          $ink-auth-card-padding-v
          ($ink-auth-card-padding-h - $ink-auth-card-radius);

        background-color: mat.get-color-from-palette(
          map-get($ink-theme, background),
          ink-auth-card
        );

        > * {
          text-align: center;
        }

        .mat-checkbox[appearance='fill'] .mat-checkbox-layout,
        .mat-form-field-appearance-fill .mat-form-field-flex {
          background-color: mat.get-color-from-palette(
            map-get($ink-theme, background),
            background
          );
        }

        .gn-link.gn-link-forgot {
          @include mat.typography-level($ink-typography, caption);

          text-decoration: underline;
          color: mat.get-color-from-palette($kms-palette-base, 700);
          margin: -1.34375em 0 2.7em;
          z-index: 0;
        }

        //  Top middle logo decoration;
        //
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          margin-top: -$ink-auth-card-decoration-logo-horizon;
          margin-left: -$ink-auth-card-decoration-logo-center;
          height: $ink-auth-card-decoration-logo-size;
          width: $ink-auth-card-decoration-logo-size;
          background-image: $ink-logo-url;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .mat-flat-button {
        border-radius: $ink-radius-baseline;
        line-height: 3;
      }
    }
  }

  .pseudo-form-field {
    padding: $ink-form-field-inset-h;
    font-weight: 500;
  }

  dl {
    padding-left: 1.5em;
  }

  dt,
  dd {
    display: block;
  }

  dt {
    float: left;
    margin: 0 0.5em 0 -1.5em;
    font-weight: 500;
  }

  dd {
    margin: 0;
  }
}

.smileys {
  width: 40px;
  height: 40px;
  margin: 5px auto;

  &.smiley-,
  &.smiley-0 {
    background: url('./assets/smileys/5a.svg') center;
    background-size: cover;
  }

  &.smiley-1 {
    background: url('./assets/smileys/1.svg') center;
    background-size: 29px;
    background-repeat: no-repeat;
  }

  &.smiley-2 {
    background: url('./assets/smileys/2.svg') center;
    background-size: 27px;
    background-repeat: no-repeat;
  }

  &.smiley-3 {
    background: url('./assets/smileys/3.svg') center;
    background-size: 29px;
    background-repeat: no-repeat;
  }

  &.smiley-4 {
    background: url('./assets/smileys/4.svg') center;
    background-size: 29px;
    background-repeat: no-repeat;
  }

  &.smiley-5 {
    background: url('./assets/smileys/5.svg') center;
    background-size: 29px;
    background-repeat: no-repeat;
  }
}
